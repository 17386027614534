import React from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionColumn, SectionMoreLink } from "@layouts/Section"
import Iframe from 'react-iframe'
import { Router } from "@reach/router"
import PrivateRoute from "@projects/PrivateRoute"

import * as css from "@css/layouts/l-article.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

let Content = ({ birthday } ) => {
    // console.log('test',birthday)
    const { title, date, content, thumbnail, thumbnailFull, acf_birthday } = birthday
    const { vimeoUrl, vimeoChatUrl } = acf_birthday
    return(
        <article className={css.lArticle}>
            <div className={css.lArticle__inner}>
                <header className={css.lArticle__header}>
                    {/* <p className={css.aDate}><time dateTime={date}>{date}</time></p> */}
                    <h1 className={css.aTitle}>{title}</h1>
                </header>
                {vimeoUrl ?
                    <div className={css.aIframe}>
                        <Iframe url={`${vimeoUrl}`} scrolling={false} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen/>
                    </div>
                    :
                    thumbnailFull && <figure className={css.lArticle__thuombnail}>
                        <Image data={thumbnailFull.node.localFile} alt="thubmanil" />
                    </figure>
                }
                <div className={css.lArticle__content} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </article>
    )
}

function BirthdayPage({data})
{
    console.log(data)
    const { birthday, birthday_2022v01, birthday_2023v01 } = data
    const { title, date, content, thumbnail, thumbnailFull } = birthday

    // const img = getImage(thumbnail.gatsbyImageData)
    // const contentRef = React.useRef()
    return (
        <>
            <SEO
                subtitle={title}
                description={content}
                image={thumbnailFull && `https://${thumbnailFull.node.localFile.publicURL}`}
                noIndex={false}
            />
            <Spacer.Header />
            <Section>
                {/* <SectionHeader
                    title="Birthday"
                    text="誕生日おめでとう！"
                    isBorder={false}
                /> */}
                <Router basepath="/member/birthday/">
                    <PrivateRoute path="/:slug"
                        component={Content}
                        birthday={birthday}
                        birthday_2022v01={birthday_2022v01}
                        birthday_2023v01={birthday_2023v01}
                        isUseBirthday/>
                </Router>
                <SectionMoreLink
                    title="Back"
                    text="プロフィール"
                    to="/member/profile/"
                    isBorder={false}
                />
            </Section>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Birthday on WpBirthday {
        slug
        date(formatString: "YYYY.MM.DD")
        title
        content
        databaseId
        uri
        acf_birthday{
            vimeoUrl
        }
        thumbnail: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 960
                            height: 960
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
        thumbnailFull: featuredImage {
            node {
                localFile {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(
                            width: 960
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                            quality: 100
                        )
                    }
                }
            }
        }
    }
    query BirthdayBySlug($id: String!){
        birthday : wpBirthday( id: { eq: $id }){
            ...Birthday
        }
        birthday_2022v01: wpBirthday(acf_birthday: {id: {eq: "2022v01"}}) {
            ...Birthday
        }
        birthday_2023v01: wpBirthday(acf_birthday: {id: {eq: "2023v01"}}) {
            ...Birthday
        }
    }
    
`
export default BirthdayPage